/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import gsap, { Expo } from "gsap";
import dynamic from "next/dynamic";
import { FunctionComponent, useEffect, useRef, useState } from "react";

import { Breadcrumbs as BreadcrumbsType } from "@/types/data";
import { StrapiGlobals } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import {
    HalfColumnGaps,
    NavigationSpacerHeightWithHalfColumnGap,
} from "@/tokens/spacing";

import { DraftModeBanner } from "@/ui/molecules/draft_mode_banner";
import { NavigationBar, NavigationBarRef } from "@/ui/organisms/navigation_bar";

import {
    useDesktopDemoValidBreakpoint,
    useNavigationFrameVisibility,
} from "@/util/animation_hooks/navigation_frame_animations";
import { buildStylesByBreakpoint } from "@/util/style_util";

/**
 * We do use a dynamic import for react-player to speed up initial
 * page load while also preventing suspense boundary issues.
 */
const ReactPlayer = dynamic(() => import("react-player/youtube"), {
    ssr: false,
});

interface NavigationFrameProps extends StrapiGlobals {
    breadcrumbs?: BreadcrumbsType;
    className?: SerializedStyles;
    previewMode?: boolean;
    renderDemo?: boolean;
    renderMinimalNav?: boolean;
    renderNotifications?: boolean;
}

export const NavigationFrame: FunctionComponent<NavigationFrameProps> = (
    props,
) => {
    /**
     * Refs
     */
    const navigationFrameRef = useRef(null);
    const navigationBarRef = useRef<NavigationBarRef>(null);
    const videoContainerRef = useRef(null);

    /**
     * State
     */
    const [isVideoMounted, setIsVideoMounted] = useState(false);
    const [isAboveFold, setIsAboveFold] = useState(true);
    const [isDesktopBreakpoint, setIsDesktopBreakpoint] = useState(false);
    const [isDemoPlaying, setIsDemoPlaying] = useState(false);
    const [didVideoIntro, setDidVideoIntro] = useState(false);

    /**
     * Animations
     */
    useNavigationFrameVisibility(
        navigationFrameRef,
        navigationBarRef,
        videoContainerRef,
        (isVisible) => {
            setIsAboveFold(isVisible);
        },
    );

    useDesktopDemoValidBreakpoint((isValidBreakpoint: boolean) => {
        setIsDesktopBreakpoint(isValidBreakpoint);
    });

    useEffect(() => {
        if (isVideoMounted) {
            if (isAboveFold && isDesktopBreakpoint) {
                setIsDemoPlaying(true);

                if (!didVideoIntro) {
                    gsap.to(videoContainerRef.current, {
                        autoAlpha: 1,
                        ease: Expo.easeInOut,
                        onComplete: () => {
                            setDidVideoIntro(true);
                        },
                        scale: 1,
                    });
                }
            } else {
                setIsDemoPlaying(false);
            }
        }
    }, [isVideoMounted, isAboveFold, isDesktopBreakpoint, didVideoIntro]);

    /**
     * Styles
     */
    const navigationFrameContainerStyles = css(
        {
            boxSizing: "border-box",
            height: "100dvh",
            pointerEvents: "none",
            position: "fixed",
            width: "100%",
            zIndex: 999,
        },
        buildStylesByBreakpoint("paddingTop", HalfColumnGaps),
        buildStylesByBreakpoint("paddingLeft", HalfColumnGaps),
        buildStylesByBreakpoint("paddingRight", HalfColumnGaps),
        buildStylesByBreakpoint("paddingBottom", HalfColumnGaps),
    );

    const navigationFrameInnerContainerStyles = css({
        height: "100%",
        position: "relative",
    });

    const navigationBarStyles = css({});

    const demoContainerStyles = css(
        {
            aspectRatio: "16 / 9",
            borderRadius: BorderRadiuses.borderMedium,
            bottom: 0,
            opacity: 0,
            overflow: "hidden",
            pointerEvents: "auto",
            position: "absolute",
            right: 0,
            transform: "scale(.9)",
            visibility: "hidden",
            width: "40ch", // Maps to notification stack
        },
        buildStylesByBreakpoint("display", {
            extraSmall: "none",
            medium: "block",
        }),
    );

    const navSpacerStyles = css(
        { width: "100%" },
        buildStylesByBreakpoint(
            "height",
            NavigationSpacerHeightWithHalfColumnGap,
        ),
    );

    /**
     * Rendering
     */
    const renderDemoVideo = () => {
        return (
            <div css={demoContainerStyles} ref={videoContainerRef}>
                <ReactPlayer
                    controls
                    playsinline
                    config={{
                        playerVars: { vq: "hd1080" },
                    }}
                    height="100%"
                    muted={true}
                    playing={isDemoPlaying}
                    url={props.globals.Demo_URL}
                    width="100%"
                    onReady={() => {
                        /**
                         * We don't need the demo video on SSR, and react-player often
                         * causes suspense boundary errors. So we use startTransition to defer
                         * hydration until the player is ready.
                         */
                        setIsVideoMounted(true);
                    }}
                />
            </div>
        );
    };

    return (
        <>
            <div css={navigationFrameContainerStyles} ref={navigationFrameRef}>
                <div css={navigationFrameInnerContainerStyles}>
                    {props.navigationBar && (
                        <NavigationBar
                            {...props.navigationBar}
                            breadcrumbs={props.breadcrumbs}
                            className={navigationBarStyles}
                            notifications={props.globals.Notifications}
                            ref={navigationBarRef}
                            renderMinimalNav={props.renderMinimalNav}
                            renderNotifications={props.renderNotifications}
                        />
                    )}

                    {props.renderDemo &&
                        props.globals.Demo_URL &&
                        renderDemoVideo()}

                    {props.previewMode && <DraftModeBanner />}
                </div>
            </div>

            <div css={navSpacerStyles} />
        </>
    );
};
