import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Observer from "gsap/dist/Observer";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import * as _ from "lodash-es";
import type { AppProps } from "next/app";

import { Breadcrumbs as BreadcrumbsType } from "@/types/data";
import {
    StrapiGlobals,
    StrapiLegacyPreFooter,
    StrapiPreFooter,
} from "@/types/strapi";

import { Layout } from "@/ui/wrappers/layout";

import { GlobalsContext } from "@/util/context/globals_context";
import { useRudderAnalyticsPageView } from "@/util/hooks/use_rudder_analytics";
import { useUserAgent } from "@/util/hooks/user_agent_hooks";
import { buildMetaData, generateMetaData } from "@/util/seo_util";

gsap.registerPlugin(useGSAP, ScrollTrigger, Observer);

export interface CustomAppProps {
    breadcrumbs?: BreadcrumbsType;
    globalsData: StrapiGlobals;
    legacyGrid?: boolean;
    legacyPrefooter?: StrapiLegacyPreFooter;
    path?: string;
    prefooter?: StrapiPreFooter;
    previewMode?: boolean;
    renderDemo?: boolean;
    renderMinimalNav?: boolean;
    renderNotifications?: boolean;
    renderPostFooter?: boolean;
    slug: string;
}

export default function App({
    Component,
    pageProps,
}: AppProps<CustomAppProps>) {
    /**
     * Analytics
     */
    useRudderAnalyticsPageView();

    /**
     * Globals Context
     */
    const { browser, isTouchDevice, operatingSystem } = useUserAgent();

    /**
     * Util
     */
    const formatMetaData = () => {
        const getPageSeo = () => {
            if (
                _.has(pageProps, "pageData") &&
                _.has(pageProps.pageData, "SEO")
            ) {
                return pageProps.pageData.SEO;
            }

            return null;
        };

        return buildMetaData(
            pageProps.globalsData.globals,
            getPageSeo(),
            pageProps.path,
        );
    };

    /**
     * Elements
     */
    return (
        <>
            {pageProps.globalsData && generateMetaData(formatMetaData())}

            <GlobalsContext.Provider
                value={{
                    browser,
                    downloads: pageProps.globalsData.downloads,
                    isTouchDevice,
                    operatingSystem,
                }}
            >
                <Layout {...pageProps}>
                    <Component {...pageProps} />
                </Layout>
            </GlobalsContext.Provider>
        </>
    );
}
