import slugify from "@sindresorhus/slugify";
import * as _ from "lodash-es";
import parseMD from "parse-md";

import { GeneralPageLevelProps } from "@/types/data";
import { StrapiMarkdownText } from "@/types/strapi";
import {
    StrapiImageExtensions,
    StrapiMedia,
    StrapiMediaType,
} from "@/types/strapi";

import { AllIcons } from "@/tokens/configs/icon_config";

/**
 * Generate unified page-level props
 */
export function generatePageLevelProps(
    generalPageLevelProps: GeneralPageLevelProps,
) {
    const { path, previewMode, slug } = generalPageLevelProps;

    return {
        path: path ?? null,
        previewMode: !!previewMode,
        slug,
    };
}

/**
 * Converts array of markdown into table of contents
 */

export const parseMarkdownIntoTableOfContents = (
    markdown: StrapiMarkdownText[],
) => {
    const parseHeading = (markdown: string) => {
        const { content } = parseMD(markdown);

        const headings = content
            .split("\n")
            .filter((line) => /^##\s/.test(line));

        return headings.map((heading) => {
            const cleanedUpHeading = heading.replace(/^#{1,2}\s/, "");

            return {
                slug: slugify(cleanedUpHeading),
                text: cleanedUpHeading,
            };
        });
    };

    const pageBodyTextContent = markdown?.map((item) => item.Text);

    const parsedPageBodyTextContent = pageBodyTextContent?.map((item) =>
        parseHeading(item),
    );

    const tableOfContents = _.flatten(parsedPageBodyTextContent);

    return tableOfContents;
};

/**
 * Format as price
 */
export function formatPrice(
    input: number | string,
    includeCents: boolean = false,
): string {
    let amount: number;

    if (typeof input === "string") {
        amount = parseFloat(input);

        if (isNaN(amount)) {
            throw new Error(
                "Invalid input: input string must be a valid number",
            );
        }
    } else {
        amount = input;
    }

    return !includeCents ? `$${Math.round(amount)}` : `$${amount.toFixed(2)}`;
}

/**
 * Generate social icon slug
 */
export function formatSocialIconSlug(text: string): AllIcons {
    switch (text.toLowerCase()) {
        case "x":
        case "twitter":
            return "X";
        case "youtube":
            return "YouTube";
        case "tiktok":
            return "TikTok";
        case "discord":
            return "Discord";
        case "github":
            return "GitHub";
        case "bluesky":
            return "Bluesky";
        default:
            return "RSS";
    }
}

/**
 * Return file name without extension
 */
export function extractFileName(
    filePath: string,
    includeExtension: boolean = false,
): string {
    const parts = filePath.split(/[/\\]/);
    const fileNameWithExtension = parts.pop();

    if (!fileNameWithExtension) return "";

    if (includeExtension) {
        return fileNameWithExtension;
    }

    const nameParts = fileNameWithExtension.split(".");

    if (nameParts.length === 1) return fileNameWithExtension;

    nameParts.pop();
    return nameParts.join(".");
}

/**
 * Removes punctuation from a string
 *
 * @param input
 * @returns
 */
export function removePunctuation(input: string): string {
    return input.replace(/[.,/#!$%^&*;:{}=\-_`~()@+?><[\]+|\\'"]/g, "");
}

export function isExternalLink(url: string): boolean {
    const warpDevRegex = /^(https?:\/\/)?(www\.)?warp\.dev/;
    return !warpDevRegex.test(url);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function detectMediaType(media: StrapiMedia): StrapiMediaType {
    return StrapiImageExtensions.includes(media.ext as any)
        ? StrapiMediaType.IMAGE
        : StrapiMediaType.VIDEO;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export function buildCustomAspectRatio(width: number, height: number) {
    function gcd(a: number, b: number): number {
        if (b === 0) {
            return a;
        }
        return gcd(b, a % b);
    }

    const greatestCommonDivisor = gcd(width, height);
    const numerator = width / greatestCommonDivisor;
    const denominator = height / greatestCommonDivisor;

    return `${numerator} / ${denominator}`;
}

export function getStrapiVideoSourceType(ext: string): string {
    const videoExtensions: { [key: string]: string } = {
        avi: "video/x-msvideo",
        flv: "video/x-flv",
        mkv: "video/x-matroska",
        mov: "video/quicktime",
        mp4: "video/mp4",
        ogg: "video/ogg",
        ogv: "video/ogg",
        webm: "video/webm",
        wmv: "video/x-ms-wmv",
    };

    const extension = removePunctuation(ext);

    if (extension && videoExtensions[extension]) {
        return videoExtensions[extension];
    } else {
        return "unknown";
    }
}

export function capitalizeFirstLetter(str: string) {
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Ensures that all production links for the marketing site
 * start with www.warp.dev. Ensures all development links are served
 * from our development environment.
 */
export function formatUrl(url: string) {
    const isProduction = process.env.NODE_ENV === "production";

    const isValidUrl =
        url.startsWith("http://warp.dev") ||
        url.startsWith("https://warp.dev") ||
        url.startsWith("http://www.warp.dev") ||
        url.startsWith("https://www.warp.dev");

    if (isProduction) {
        if (isValidUrl) {
            return (url = url.replace(
                /^https?:\/\/(www\.)?warp.dev/,
                process.env.NEXT_PUBLIC_PRODUCTION_URL!,
            ));
        }

        return url;
    } else {
        if (isValidUrl) {
            return new URL(url).pathname;
        }

        return url;
    }
}
