/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ElementAlignment } from "@/types/ui";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { FontFamilies } from "@/tokens/fonts";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { Text } from "@/ui/atoms/text";
import { TextInput } from "@/ui/atoms/text_input";

import {
    processNestedFormDataOnSubmit,
    submitNestedForm,
} from "@/util/form_util";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { useRudderAnalyticsAnonymousId } from "@/util/hooks/use_rudder_analytics";
import {
    buildStylesByBreakpoint,
    buildStylesByStringOrObject,
} from "@/util/style_util";
import {
    generateFontStyles,
    getBaseTypeStyles,
} from "@/util/tokens/typography_util";

interface NestedFormProps {
    alignment?: ElementAlignment;
    className?: SerializedStyles;
    formEndpoint: string;
    formName: string;
    placeholder: string;
    shouldStackInputAndButton?: boolean;
    submitText: string;
    title?: string;
}

export type NestedFormValue = Record<string, string>;

export const NestedForm: FunctionComponent<NestedFormProps> = ({
    alignment = {
        extraSmall: "center",
        large: "start",
    },
    ...props
}) => {
    /**
     * Globals
     */
    const theme = useTypedTheme();
    const { placeholder, submitText, title } = props;

    /**
     * State management
     */
    const [buttonText, setButtonText] = useState(submitText);

    const [successMessage, setSuccessMessage] = useState(
        "Thank you! Your submission has been received!",
    );

    const [showForm, setShowForm] = useState(true);

    /**
     * Hooks
     */
    const {
        formState: { errors, isSubmitted, isSubmitting },
        getValues,
        handleSubmit,
        register,
        reset,
        setError,
    } = useForm<NestedFormValue>({
        shouldFocusError: true,
    });

    useEffect(() => {
        setButtonText(isSubmitting ? "Please wait..." : submitText);
    }, [isSubmitting, submitText]);

    /**
     * Handlers
     */

    const getAnonymousId = useRudderAnalyticsAnonymousId();

    const onSubmit = async (data: NestedFormValue) => {
        const { field, userErrors } = processNestedFormDataOnSubmit(data);

        if (userErrors) {
            return userErrors.forEach((error) => {
                setError(error.key as string, { message: error.message });
            });
        }

        try {
            await submitNestedForm({
                data: {
                    anonymousid: getAnonymousId(),
                    field: field.value,
                },
                endpoint: props.formEndpoint,
                name: props.formName,
            });

            setShowForm(false);
            reset();
            setSuccessMessage("Thank you! Your submission has been received!");
        } catch (err) {
            console.error(err);
            setError("root.submitError", {
                message:
                    "Form submission failed. Please try again or contact support.",
            });
        }
    };

    const handleBlur = () => {
        if (!isSubmitted) {
            return;
        }

        const { userErrors } = processNestedFormDataOnSubmit(getValues());

        if (userErrors) {
            return userErrors.forEach((error) => {
                setError(error.key as string, { message: error.message });
            });
        }
    };

    /**
     * Styles
     */
    const containerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
            width: "100%",
        },
        buildStylesByStringOrObject("alignItems", alignment),
        props.className,
    );

    const backgroundStyles = css({
        "@media (prefers-contrast: more)": {
            backgroundColor: Colors["neutral-80"],
        },
        backgroundColor: Colors["lighten-10"],
    });

    const stackedGroupWrapperStyles = css({
        display: "flex",
        flexDirection: "column",
        gap: Spacing["spacing-3"],
        position: "relative",
        width: "100%",
    });

    const embeddedGroupWrapperStyles = css(backgroundStyles, {
        alignItems: "center",
        border: `1px solid ${errors.email ? Colors["primary-red"] : Colors["transparent"]}`,
        borderRadius: BorderRadiuses.borderLarge,
        boxSizing: "border-box",
        display: "flex",
        fontWeight: "normal",
        justifyContent: "space-between",
        paddingLeft: Spacing["spacing-2"],
        position: "relative",
        width: "100%",
    });

    const stackedInputStyles = css(backgroundStyles, {
        border: `1px solid ${errors.email ? Colors["primary-red"] : Colors["transparent"]}`,
        borderRadius: BorderRadiuses.borderLarge,
        fontWeight: "normal",
        padding: Spacing["spacing-4"],
        textAlign: "center",
    });

    const embeddedInputStyles = css(
        FontFamilies["sans"].style,
        getBaseTypeStyles("textPrimary"),
        generateFontStyles("DownloadButtonCTA", {}),
        {
            backgroundColor: "transparent",
            color: "textPrimary",
            flex: "1 1 100%",
            fontWeight: "normal",
            justifyContent: "flex-start",
        },
        {
            "&::placeholder": {
                color: "textPrimary",
            },
        },
    );

    const stackedFormButtonStyles = css({
        justifyContent: "center",
    });

    const embeddedButtonStyles = css(
        {
            flexShrink: 0,
            margin: Spacing["spacing-2"],
        },
        buildStylesByBreakpoint("paddingInline", {
            extraSmall: Spacing["spacing-2"],
            medium: Spacing["spacing-3"],
        }),
    );

    const submitFeedbackMessageStyles = css({
        backgroundColor: theme.backgrounds.backgroundPrimary,
        borderRadius: BorderRadiuses.borderMedium,
        marginTop: Spacing["spacing-4"],
        padding: Spacing["spacing-3"],
    });

    return showForm ? (
        <div css={containerStyles}>
            {title && (
                <Text marginBottom="InputLabelMargin" themeKey="textPrimary">
                    {title}
                </Text>
            )}

            <form
                noValidate
                css={
                    props.shouldStackInputAndButton
                        ? stackedGroupWrapperStyles
                        : embeddedGroupWrapperStyles
                }
            >
                <TextInput
                    required
                    className={
                        props.shouldStackInputAndButton
                            ? stackedInputStyles
                            : embeddedInputStyles
                    }
                    errorMessage={
                        props.shouldStackInputAndButton && errors.email?.message
                            ? (errors.email?.message as string)
                            : undefined
                    }
                    fieldType="email"
                    label={title || "Input"}
                    labelClassName={
                        props.shouldStackInputAndButton
                            ? css({
                                  textAlign: "center", // to center error message
                              })
                            : undefined
                    }
                    name="email"
                    placeholder={placeholder}
                    register={register}
                    showInputLabel={false}
                    type="string"
                    onBlur={handleBlur}
                />

                <Button
                    className={
                        props.shouldStackInputAndButton
                            ? stackedFormButtonStyles
                            : embeddedButtonStyles
                    }
                    fontSize="DownloadButtonCTA"
                    isDisabled={isSubmitting}
                    textClassName={css({ textAlign: "center" })}
                    variant="primary"
                    onClick={handleSubmit(onSubmit)}
                >
                    {buttonText}
                </Button>
            </form>

            {!props.shouldStackInputAndButton && errors.email && (
                <Text color="primary-red" marginTop="InputLabelMargin">
                    {errors.email?.message}
                </Text>
            )}

            {errors.root?.submitError && (
                <Text
                    className={submitFeedbackMessageStyles}
                    color="primary-red"
                    fontSize="TextSmall"
                    textAlign="center"
                >
                    {errors.root.submitError.message}
                </Text>
            )}
        </div>
    ) : (
        <Text
            className={submitFeedbackMessageStyles}
            fontSize="TextSmall"
            textAlign="center"
            themeKey="textSecondary"
        >
            {successMessage}
        </Text>
    );
};
