import { Attribute, Common } from "@strapi/strapi";
import type { ImageProps } from "next/image";

import { ImageAspectRatioString } from "@/tokens/media";

export type GetAttributesValues<TSchemaUID extends Common.UID.Schema> =
    Attribute.GetValues<TSchemaUID> & {
        __component?: TSchemaUID extends Common.UID.Component
            ? TSchemaUID
            : undefined;
        contentType?: string;
    };

/**
 * Strapi Utilities
 */
export interface StrapiSlugCollection {
    contentType: Common.UID.Schema;
    slug: string;
}

/**
 * Collection Shortcuts
 */
export type StrapiAuthor = GetAttributesValues<"api::data-author.data-author">;
export type StrapiBlogArticle =
    GetAttributesValues<"api::page-blog-article.page-blog-article">;
export type StrapiCompetitor =
    GetAttributesValues<"api::page-competitor.page-competitor">;
export type StrapiContactFormPage =
    GetAttributesValues<"api::page-contact-form.page-contact-form">;
export type StrapiCta = GetAttributesValues<"api::component-cta.component-cta">;
export type StrapiHomePageExperiment = Omit<
    GetAttributesValues<"api::exp-home.exp-home">,
    "Slug"
>;
export type StrapiProductPage = Omit<
    GetAttributesValues<"api::page-product.page-product">,
    "Slug"
>;
export type StrapiFooter =
    GetAttributesValues<"api::component-footer.component-footer">;
export type StrapiGeneralTextPage =
    GetAttributesValues<"api::page-general-text.page-general-text">;
export type StrapiInvestor =
    GetAttributesValues<"api::data-investor.data-investor">;
export type StrapiLegacyPreFooter =
    GetAttributesValues<"api::component-legacy-pre-footer.component-legacy-pre-footer">;
export type StrapiPreFooter =
    GetAttributesValues<"api::component-pre-footer.component-pre-footer">;
export type StrapiPlan = GetAttributesValues<"api::data-plan.data-plan">;
export type StrapiPricingPlan = GetAttributesValues<"api::data-plan.data-plan">;
export type StrapiProductFeaturePage =
    GetAttributesValues<"api::page-product-feature.page-product-feature">;
export type StrapiTerminusArticle =
    GetAttributesValues<"api::page-terminus-article.page-terminus-article">;
export type StrapiTerminusTopic =
    GetAttributesValues<"api::data-terminus-topic.data-terminus-topic">;
export type StrapiWarpEmployee =
    GetAttributesValues<"api::data-warper.data-warper">;
export type StrapiTheme =
    GetAttributesValues<"api::data-page-theme.data-page-theme">;

/**
 * Single Type Shortcuts
 */
export type StrapiAboutPage = GetAttributesValues<"api::page-about.page-about">;
export type StrapiAgentModePage =
    GetAttributesValues<"api::page-agent-mode-launch.page-agent-mode-launch">;
export type StrapiBlogHome =
    GetAttributesValues<"api::page-blog-home.page-blog-home">;
export type StrapiCareersPage =
    GetAttributesValues<"api::page-careers.page-careers">;
export type StrapiCompareHome =
    GetAttributesValues<"api::page-compare.page-compare">;
export type StrapiContactPage =
    GetAttributesValues<"api::page-contact.page-contact">;
export type StrapiDownloadGroup =
    GetAttributesValues<"api::data-download-group.data-download-group">;
export type StrapiDownloadPage =
    GetAttributesValues<"api::page-download.page-download">;
export type StrapiFAQPage = GetAttributesValues<"api::page-faqs.page-faqs">;
export type StrapiGlobalData =
    GetAttributesValues<"api::data-globals.data-globals">;
export type StrapiHomePage = GetAttributesValues<"api::page-home.page-home">;
export type StrapiLegacyNavBar =
    GetAttributesValues<"api::component-legacy-nav-bar.component-legacy-nav-bar">;
export type StrapiPricingPage =
    GetAttributesValues<"api::page-pricing.page-pricing">;
export type StrapiPrivacyPage =
    GetAttributesValues<"api::page-privacy.page-privacy">;
export type StrapiTerminusHome =
    GetAttributesValues<"api::page-terminus-home.page-terminus-home">;
export type StrapiStateOfTheCLI2023Page =
    GetAttributesValues<"api::page-state-of-cli-2023.page-state-of-cli-2023">;
export type StrapiNavBar =
    GetAttributesValues<"api::component-nav-bar.component-nav-bar">;

/**
 * Data
 */
export type StrapiDownloadOption =
    GetAttributesValues<"data.data-download-option">;
export type StrapiSEO = GetAttributesValues<"data.data-seo">;

/**
 * Components
 */
export type StrapiAmbientList =
    GetAttributesValues<"sections.section-ambient-list">;
export type StrapiBenefitItem =
    GetAttributesValues<"components.component-benefit-item">;
export type StrapiLegacyBenefitsGrid =
    GetAttributesValues<"sections.section-legacy-benefits">;
export type StrapiDownloadRow =
    GetAttributesValues<"components.component-download-row">;
export type StrapiFAQItem =
    GetAttributesValues<"components.component-faq-item">;
export type StrapiLegacyFeatureItem =
    GetAttributesValues<"components.component-legacy-feature-item">;
export type StrapiFeatureTable =
    GetAttributesValues<"components.component-feature-table">;
export type StrapiFooterLinkGroupArrayProps =
    GetAttributesValues<"components.component-footer-link-group">;
export type StrapiPlanMatrixRow =
    GetAttributesValues<"components.component-plan-matrix-row">;
export type StrapiTextFeature =
    GetAttributesValues<"sections.section-legacy-feature">;
export type StrapiCompetitorMatrixRow =
    GetAttributesValues<"components.component-competitor-matrix-row">;
export type StrapiCompetitorMatrixGroupItem =
    GetAttributesValues<"components.competitor-matrix-group-item">;
export type StrapiInvestors = GetAttributesValues<"sections.section-investors">;
export type StrapiContactItem =
    GetAttributesValues<"components.component-contact-page-item">;
export type StrapiLegacyHero =
    GetAttributesValues<"sections.section-legacy-hero">;
export type StrapiFeaturedArticle =
    GetAttributesValues<"api::page-blog-article.page-blog-article">;
export type StrapiBlogTopic =
    GetAttributesValues<"api::data-blog-topic.data-blog-topic">;
export type StrapiBlogGlobals =
    GetAttributesValues<"api::data-blog-global.data-blog-global">;
export type StrapiLegacyNavGroup =
    GetAttributesValues<"components.component-legacy-nav-group">;
export type StrapiLegacyNotification =
    GetAttributesValues<"components.component-legacy-notification">;
export type StrapiCareersHero =
    GetAttributesValues<"sections.section-careers-hero">;
export type StrapiFaqSection = GetAttributesValues<"sections.section-fa-qs">;
export type StrapiExternalVideoRow =
    GetAttributesValues<"components.component-external-video-row">;
export type StrapiBillboardCarousel =
    GetAttributesValues<"sections.section-billboard-carousel">;
export type StrapiCustomerLogoReel =
    GetAttributesValues<"sections.section-customer-logo-reel">;
export type StrapiMosaic = GetAttributesValues<"sections.section-mosaic">;
export type StrapiProductFeature =
    GetAttributesValues<"sections.section-product-feature">;
export type StrapiLegacyProductFeature =
    GetAttributesValues<"sections.section-legacy-feature">;
export type StrapiLegacyCarousel =
    GetAttributesValues<"sections.section-legacy-carousel">;
export type StrapiLegacyCarouselRow =
    GetAttributesValues<"components.component-legacy-carousel-row">;
export type StrapiLegacyBigTileGrid =
    GetAttributesValues<"sections.section-legacy-big-tile-grid">;
export type StrapiTestimonial =
    GetAttributesValues<"sections.section-testimonial">;
export type StrapiLegacyTweetGrid =
    GetAttributesValues<"sections.section-legacy-tweet-grid">;
export type StrapiTerminusSidebarAd =
    GetAttributesValues<"components.component-terminus-sidebar-ad">;
export type StrapiTerminusTopicTheme =
    GetAttributesValues<"api::data-terminus-topic.data-terminus-topic">["Theme"];
export type StrapiStateOfCli2023DonutChartsList =
    GetAttributesValues<"components.cli-2023-pie-charts-list">;
export type StrapiStateOfCli2023Hero =
    GetAttributesValues<"sections.section-cli-legacy-hero">;
export type StrapiStateOfCli2023BarChart =
    GetAttributesValues<"components.cli-2023-bar-chart">;
export type StrapiStateOfCli2023BubbleChartList =
    GetAttributesValues<"components.cli-2023-bubble-charts-list">;
export type StrapiStateOfCli2023BubbleChart =
    GetAttributesValues<"components.cli-2023-bubble-chart">;
export type StrapiStateOfCli2023BodyText =
    GetAttributesValues<"components.cli-2023-body-text">;
export type StrapiStateOfCli2023KeyTakeAways =
    GetAttributesValues<"components.cli-2023-key-takeaways">;
export type StrapiStateOfCli2023MarqueeList =
    GetAttributesValues<"components.cli-2023-marquee-list">;
export type StrapiStateOfCli2023MarqueeItem =
    GetAttributesValues<"components.cli-2023-marquee-item">;
export type StrapiSubfeatureSection =
    GetAttributesValues<"bespoke.ss-subfeature">;
export type StrapiSessionSharingFeature =
    GetAttributesValues<"bespoke.ss-feature">;
export type StrapiGhostTextHero =
    GetAttributesValues<"sections.section-ghost-text-hero">;
export type StrapiBigTextHero =
    GetAttributesValues<"sections.section-big-text-hero">;
export type StrapiEmphasizedHero =
    GetAttributesValues<"sections.section-emphasized-hero">;
export type StrapiTextHeader =
    GetAttributesValues<"components.component-header">;
export type StrapiIconGraphic =
    GetAttributesValues<"components.component-icon-graphic">;
export type StrapiSpecSheet =
    GetAttributesValues<"sections.section-spec-sheet">;
export type StrapiSpecGroup =
    GetAttributesValues<"components.component-spec-group">;
export type StrapiPrivacy = GetAttributesValues<"sections.section-privacy">;
export type StrapiPrivacyList =
    GetAttributesValues<"components.component-privacy-list">;
export type StrapiNotification =
    GetAttributesValues<"components.component-notification">;
export type StrapiNavGroup =
    GetAttributesValues<"components.component-nav-group">;
export type StrapiNavGroupLink =
    GetAttributesValues<"components.component-nav-group-link">;

/**
 * Primitives
 */
export type StrapiLottieAnimation =
    GetAttributesValues<"primitives.primitive-legacy-lottie">;
export type StrapiMediaPrimitive =
    GetAttributesValues<"primitives.primitive-media">;
export type StrapiPrimitiveLink =
    GetAttributesValues<"primitives.primitive-link">;
export type StrapiPrimitiveButton =
    GetAttributesValues<"primitives.primitive-button">;
export type StrapiTextInput =
    GetAttributesValues<"primitives.primitive-text-input">;
export type StrapiMarkdownText =
    GetAttributesValues<"primitives.primitive-markdown-text">;
export type StrapiExternalVideo =
    GetAttributesValues<"primitives.primitive-external-video">;
export type StrapiPrimitiveMedia =
    GetAttributesValues<"primitives.primitive-media">;
export type StrapiQuotePrimitive =
    GetAttributesValues<"primitives.primitive-quote">;
export type StrapiEmbedPrimitive =
    GetAttributesValues<"primitives.primitive-embed">;

/**
 * Assemblies of Strapi types
 */
export interface StrapiGlobals {
    downloads: Array<StrapiDownloadGroup>;
    footer: StrapiFooter;
    globals: StrapiGlobalData;
    legacyNavigationBar: StrapiLegacyNavBar;
    navigationBar: StrapiNavBar;
}

export type StrapiCtaDynamicZone = StrapiCta["Primary_Action"][0];
export type StrapiLegacyNavLinkDynamicZone = Array<
    StrapiPrimitiveLink | StrapiLegacyNavGroup
>;

export type StrapiCompetitorMatrixGroupRow =
    GetAttributesValues<"components.competitor-matrix-group-row">;

export type StrapiCompetitorMatrixGroupRowItems =
    StrapiCompetitorMatrixGroupItem[];

export type StrapiProductFeaturePageDynamicZone = Array<
    StrapiFaqSection | StrapiLegacyProductFeature | StrapiTestimonial
>;

export type StrapiLottieDynamicZone = StrapiLottieAnimation["Lottie_Files"][0];

export type StrapiArticleBodyDynamicZone = Array<
    | GetAttributesValues<"primitives.primitive-markdown-text">
    | GetAttributesValues<"primitives.primitive-quote">
    | GetAttributesValues<"primitives.primitive-media">
    | StrapiPrimitiveButton
    | StrapiExtendedEmbedPrimitive
    | StrapiExternalVideoWithDims
>;

/**
 * Strapi Type Extensions
 */
export type StrapiExternalVideoWithPoster = StrapiExternalVideo & {
    posterImage?: string;
};

/**
 * Extends StrapiExternalVideo to include aspect ratio
 */

export type StrapiExternalVideoWithDims = StrapiExternalVideo & {
    aspectRatio?: number;
};

/**
 * Extends StrapiEmbedPrimitive to include Github Gist-related data
 * that we pull in from getBlogArticleSlugPage in strapi_util
 */
export interface StrapiExtendedEmbedPrimitive extends StrapiEmbedPrimitive {
    HTML: string;
    Stylesheet: string;
}

/**
 * Edits the Article_Body to include the extended Embed Primitive
 */
export type StrapiBlogExtended = Omit<StrapiBlogArticle, "Article_Body"> & {
    Article_Body: StrapiArticleBodyDynamicZone;
};

/**
 * Removes Article_Body and id and adds Article_Description
 */
export type StrapiBlogRss = Omit<StrapiBlogArticle, "Article_Body" | "id"> & {
    Article_Body: StrapiArticleBodyDynamicZone;
};

export type StrapiTerminusExtended = Omit<
    StrapiTerminusArticle,
    "Article_Body"
> & {
    Article_Body: StrapiArticleBodyDynamicZone;
};

/**
 * Icons
 */
export type StrapiIcon = Exclude<
    | StrapiBenefitItem["Icon"]
    | StrapiLegacyFeatureItem["Icon"]
    | StrapiCompetitorMatrixGroupItem["Icon"],
    undefined
>;

/**
 * Media
 */
export const StrapiVideoExtensions = [".mp4", ".ogg", ".mov"] as const;
export type StrapiVideoExtension = (typeof StrapiVideoExtensions)[number];

export const StrapiImageExtensions = [
    ".jpg",
    ".png",
    ".jpeg",
    ".webp",
    ".gif",
    ".svg",
] as const;
export type StrapiImageExtension = (typeof StrapiImageExtensions)[number];

export type StrapiMediaExtension = StrapiVideoExtension | StrapiImageExtension;

export type StrapiAspectRatio = Exclude<
    GetAttributesValues<"primitives.primitive-media">["Aspect_Ratio"],
    undefined
>;
export type StrapiAspectRatios = Array<StrapiAspectRatio>;

export const StrapiAspectRatios: StrapiAspectRatios = [
    "Square_1x1",
    "Portrait_4x5",
    "Wide_3x2",
    "Wide_4x3",
    "Wide_5x4",
    "Ultrawide_16x9",
    "Ultrawide_2x1",
] as const;

export interface StrapiMedia {
    alternativeText: string;
    aspectRatio?: ImageAspectRatioString | "stretch";
    ext?: StrapiMediaExtension;
    height: number;
    lqip?: ImageProps["placeholder"] | undefined;
    url: string;
    width: number;
}

export enum StrapiMediaType {
    IMAGE,
    VIDEO,
}
