/* eslint-disable sort-keys-custom-order/object-keys */
import { BreakpointObject } from "@/types/tokens/breakpoints";

import { ColumnGaps, Spacing, SpacingKey } from "@/tokens/spacing";

/*
 * Spacing groups
 */
export type SpacingSet = BreakpointObject<string>;

export type SpacingStyleKey =
    // General
    | "Default"
    | "DefaultSectionMargin"
    | "None"

    // Download Page
    | "DownloadMobileCardHeading"
    | "DownloadGroupContainer"

    // Popover card
    | "PopoverCardTitleMarginBottom"

    // Compare page
    | "ComparePageMarginTop"
    | "ComparePageMarginBottom"
    | "CompareMatrixRowTitleMarginTop"
    | "CompareMatrixRowTitleMarginBottom"
    | "CompareMatrixFeaturesHeaderMarginTop"
    | "CompareMatrixRowContentMarginTop"
    | "CompareMatrixRowDividerMarginTop"
    | "CompareMatrixFeaturesLogosMarginBottom"
    | "CompareMatrixRowDividerGroupMarginTop"
    | "CompareMatrixRowDividerGroupMarginBottom"
    | "CompareCTAMarginBottom"

    // Longform Page
    | "LongformPageContainer"
    | "LongformHeadlineMarginBottom"
    | "LongformHeadlineLarge"
    | "LongformHeadlineDefault"
    | "LongformHeadlineSmall"
    | "LongformParagraph"
    | "LongformDividerMargin"

    // Text Features
    | "TextFeatureDefaultBlock"
    | "TextFeatureHeadlineSecondaryMargin"
    | "TextFeatureCTAButtonMargin"

    // List
    | "List"
    | "ListItem"
    | "ArticleListItem"

    // PreFooter
    | "LegacyPreFooter"
    | "PreFooterHeadline"
    | "PreFooterHeadlineAnimated"
    | "PreFooterMedia"

    // Pricing Matrix
    | "PricingMatrixStickyNavMarginTop"
    | "PricingMatrixGroupTitle"
    | "PricingMatrixRowItem"
    | "PricingMatrixRowItemInfo"
    | "PricingDataPrivacyBlock"
    | "PricingParagraphMargin"

    // Contact page
    | "ContactPageHeadlineMarginTop"
    | "ContactPageHeadlineMarginBottom"
    | "ContactFormPageHeadlineMargin"
    | "ContactPageFormMargin"
    | "ContactItemCTAMarginTop"
    | "ContactCardTitleMarginBottom"
    | "ContactCardAuthorMarginBottom"

    // Compare page
    | "ComparePageMarginTop"
    | "ComparePageMarginBottom"

    // List
    | "List"
    | "ListItem"

    // Product Hero
    | "ProductHeroText"
    | "ProductHeroCta"
    | "ProductHeroBlocks"

    // CTA Group
    | "CtaGroupRow"
    | "CtaGroupColumn"

    // Blog
    | "FeaturedArticleHeroMargin"
    | "FeaturedArticleHeroHeadlineMargin"
    | "FeaturedArticleAuthorMargin"
    | "TopicChipListMargin"
    | "BlogArticleCardMargin"
    | "BlogArchiveArticleRowDividerMargin"
    | "BlogArchiveArticleListMargin"
    | "BlogArchiveArticleListHeadlineMargin"
    | "BlogArticleTitleMargin"
    | "BlogArticleHeroImageMargin"
    | "BlogArticleBodyMargin"
    | "BlogArticleHeadingMargin"

    // Terminus
    | "TerminusHomeFeaturedHeadlineMarginBottom"
    | "TerminusHomeFeaturedTopicMarginBottom"
    | "TerminusHomeHeroContentMarginBottom"
    | "TerminusHomeHeroDescriptionMarginTop"
    | "TerminusHomeHeroMarginBottom"
    | "TerminusTopicHeroPretitleMarginBottom"
    | "TerminusTopicHeroDescriptionMarginTop"
    | "TerminusSidebarTopicsMarginBottom"
    | "TerminusArticleCardDescriptionMarginTop"
    | "TerminusArticleCardDescriptionMarginBottom"
    | "TerminusArticleListMarginBottom"
    | "TerminusArticleTopDividerMarginTop"
    | "TerminusArticleTopDividerMarginBottom"
    | "TerminusArticleBottomDividerMarginTop"
    | "TerminusArticleBottomDividerMarginBottom"
    | "TerminusAuthorCardTopicsMarginBottom"
    | "TerminusArchiveTitleMarginBottom"
    | "TerminusArchiveTitleMarginTop"
    | "TerminusArticleParagraphMargin"
    | "TerminusArticleTitleMargin"
    | "TerminusArticleHeroImageMargin"
    | "TerminusArticleBodyMargin"
    | "TerminusArticleHeadingMargin"
    | "TerminusDividerMargin"

    // Input Labels
    | "InputLabelMargin"

    // Mobile Subnav Link Group
    | "MobileSubnavLinkGroupTop"
    | "MobileSubnavLinkGroupBottom"

    // FAQ Section
    | "FaqSectionBottomMargin"

    /*
     * Agent Mode
     */
    | "AgentModePageMargin"
    | "AgentModeVideoCaptionMargin"
    | "AgentModeHeading2MarginTop"
    | "AgentModeHeading3MarginTop"

    // Product Page
    | "ProductPageParentSlice"
    | "ProductPageChildSlice"

    // Legacy Benefit Item
    | "LegacyBenefitItemIcon"
    | "LegacyBenefitItemText"
    | "LegacyBenefitItem"

    // Legacy Features Section
    | "LegacyFeaturesHeroText"

    // Legacy Feature Item (Vertical)
    | "LegacyFeatureHorizontalItem"
    | "LegacyFeatureHorizontalItemEyebrow"
    | "LegacyFeatureHorizontalItemHeadline"
    | "LegacyFeatureHorizontalItemLink"

    // Legacy Feature Item (Vertical)
    | "LegacyFeatureVerticalItemsContainer"
    | "LegacyFeatureVerticalItem"
    | "LegacyFeatureVerticalItemTextContainer"
    | "LegacyFeatureVerticalItemText"

    // Legacy Testimonial
    | "LegacyTestimonialHeadshot"
    | "LegacyTestimonialItems"
    | "LegacyTestimonialAttribution"

    // Legacy Tweet Grid
    | "LegacyTweetGridContainer"

    // About page
    | "AboutEthosHeadlineMarginBottom"
    | "AboutHeadlineMarginBottom"
    | "AboutPageMarginTop"

    // Careers page
    | "CareersContainerMargin"
    | "CareersHero"
    | "CareersHeroSubHeadline"
    | "CareersHeroCTA"
    | "CareersHeroTeam"
    | "CareersHeroTeamCaption"
    | "CareersOverviewItemsContainer"
    | "CareersWorkCTA"
    | "CareersWorkValuesItemsContainer"
    | "CareersWorkValuesDescription"
    | "CareersBenefitsItemsContainer"
    | "CareersRolesJobOpenings"
    | "CareersRolesJobLocation"
    | "CareersRolesJobDivider"

    // Article
    | "ArticleParagraphMargin"
    | "ArticleHeading1"
    | "ArticleHeading2"
    | "ArticleHeading3"
    | "ArticleHeading4"
    | "ArticleHeading5"
    | "ArticleHeading6"
    | "ArticleList"
    | "ArticleLargeMargin"
    | "ArticleQuote"
    | "ArticleCaption"
    | "DefaultArticleMargin"

    // State of CLI
    | "StateOfCliHeroHeadline"
    | "StateOfCliKeyTakeawayTitle"
    | "StateOfCliKeyTakeawayDescription"

    // Text Header Component
    | "TextHeaderEyebrow"
    | "TextHeaderSubheadline"
    | "TextHeaderCta"

    // Page Hero Components
    | "DefaultPageHeroTop"
    | "DefaultPageHeroRowGap"

    // 2024 Redesign Page Defaults
    | "DefaultSectionGap"
    | "MinimumSectionGap"
    | "DefaultInterSectionGap"
    | "InterSectionGapSmall"

    // Spec Sheet
    | "SpecSheetListSpacing"
    | "SpecSheetDiveDeeper"

    // Privacy List
    | "PrivacyListMargin"
    | "PrivacyListSubheadline"
    | "PrivacyListExploreLink"
    | "PrivacyListRowTop"
    | "PrivacyListRowBottom"
    | "PrivacyListSubheadlineContainer"

    // Billboard Carousel
    | "BillboardScrollHeadline"
    | "BillboardSubheadline";

// Legacy Feature Item (Horizontal)

export const spacingSets: { [key in SpacingStyleKey]: SpacingSet } = {
    // General
    Default: {
        extraSmall: Spacing["spacing-8"],
    },

    DefaultSectionMargin: {
        extraSmall: Spacing["spacing-18"],
        medium: Spacing["spacing-18"],
        large: Spacing["spacing-24"],
    },

    // None
    None: {
        extraSmall: Spacing["spacing-0"],
    },

    // Download Page
    DownloadMobileCardHeading: {
        extraSmall: Spacing["spacing-2"],
        large: Spacing["spacing-3"],
    },

    DownloadGroupContainer: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-6"],
        large: Spacing["spacing-8"],
    },

    // PopoverCard
    PopoverCardTitleMarginBottom: {
        extraSmall: Spacing["spacing-2"],
    },

    // Longform Pages
    LongformPageContainer: {
        /** TODO: Confirm if this works with Articles + Terminus pages */
        extraSmall: Spacing["spacing-8"],
        medium: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    LongformHeadlineMarginBottom: {
        extraSmall: Spacing["spacing-6"],
        medium: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    LongformHeadlineLarge: {
        extraSmall: Spacing["spacing-12"],
        large: Spacing["spacing-16"],
    },
    LongformHeadlineDefault: {
        extraSmall: Spacing["spacing-12"],
    },
    LongformHeadlineSmall: {
        extraSmall: Spacing["spacing-6"],
    },
    LongformParagraph: {
        extraSmall: Spacing["spacing-3"],
    },
    LongformDividerMargin: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-18"],
    },

    // Text Features
    TextFeatureDefaultBlock: {
        extraSmall: Spacing["spacing-8"],
    },
    TextFeatureHeadlineSecondaryMargin: {
        extraSmall: Spacing["spacing-2"],
    },
    TextFeatureCTAButtonMargin: {
        extraSmall: Spacing["spacing-12"],
    },

    // Contact page
    ContactPageHeadlineMarginTop: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-18"],
        large: Spacing["spacing-24"],
    },
    ContactPageHeadlineMarginBottom: {
        extraSmall: Spacing["spacing-4"],
        medium: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    ContactFormPageHeadlineMargin: {
        extraSmall: Spacing["spacing-4"],
    },

    ContactPageFormMargin: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-0"],
    },
    ContactItemCTAMarginTop: {
        extraSmall: Spacing["spacing-8"],
        medium: Spacing["spacing-12"],
    },
    ContactCardTitleMarginBottom: {
        extraSmall: Spacing["spacing-1"],
    },
    ContactCardAuthorMarginBottom: {
        extraSmall: Spacing["spacing-8"],
    },

    // Compare Page
    ComparePageMarginTop: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-18"],
    },
    ComparePageMarginBottom: {
        extraSmall: Spacing["spacing-8"],
        medium: Spacing["spacing-24"],
        large: Spacing["spacing-32"],
    },
    CompareMatrixRowTitleMarginTop: {
        extraSmall: Spacing["spacing-4"],
    },
    CompareMatrixRowTitleMarginBottom: {
        extraSmall: Spacing["spacing-3"],
    },
    CompareMatrixFeaturesHeaderMarginTop: {
        extraSmall: Spacing["spacing-3"],
        medium: Spacing["spacing-6"],
    },
    CompareMatrixFeaturesLogosMarginBottom: {
        extraSmall: Spacing["spacing-4"],
        medium: Spacing["spacing-6"],
    },
    CompareMatrixRowContentMarginTop: {
        medium: Spacing["spacing-4"],
    },
    CompareMatrixRowDividerMarginTop: {
        extraSmall: Spacing["spacing-8"],
    },
    CompareMatrixRowDividerGroupMarginTop: {
        extraSmall: Spacing["spacing-5"],
    },
    CompareMatrixRowDividerGroupMarginBottom: {
        extraSmall: Spacing["spacing-5"],
    },
    CompareCTAMarginBottom: {
        extraSmall: Spacing["spacing-18"],
        medium: Spacing["spacing-24"],
    },

    // List
    List: {
        extraSmall: Spacing["spacing-2"],
    },
    ListItem: {
        extraSmall: Spacing["spacing-2"],
    },
    ArticleListItem: {
        extraSmall: Spacing["spacing-1"],
    },

    // PreFooter
    LegacyPreFooter: {
        extraSmall: Spacing["spacing-18"],
        medium: Spacing["spacing-24"],
        large: Spacing["spacing-32"],
    },
    PreFooterHeadline: {
        extraSmall: Spacing["spacing-2"],
        medium: Spacing["spacing-4"],
    },
    PreFooterHeadlineAnimated: {
        extraSmall: Spacing["spacing-4"],
        medium: Spacing["spacing-5"],
    },
    PreFooterMedia: {
        extraSmall: Spacing["spacing-18"],
        large: Spacing["spacing-24"],
    },

    // Pricing Matrix
    PricingMatrixStickyNavMarginTop: {
        extraSmall: Spacing["spacing-4"],
    },
    PricingMatrixGroupTitle: {
        extraSmall: Spacing["spacing-6"],
        medium: Spacing["spacing-5"],
        large: Spacing["spacing-4"],
    },
    PricingMatrixRowItem: {
        extraSmall: Spacing["spacing-4"],
        medium: Spacing["spacing-0"],
    },
    PricingMatrixRowItemInfo: {
        extraSmall: Spacing["spacing-1"],
    },
    PricingDataPrivacyBlock: {
        extraSmall: Spacing["spacing-18"],
    },
    PricingParagraphMargin: {
        extraSmall: Spacing["spacing-6"],
        medium: Spacing["spacing-8"],
    },

    // Product Hero
    ProductHeroText: {
        extraSmall: Spacing["spacing-2"],
        medium: Spacing["spacing-4"],
    },
    ProductHeroCta: {
        extraSmall: Spacing["spacing-8"],
    },
    ProductHeroBlocks: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-16"],
        large: Spacing["spacing-0"],
    },

    // CTA Group
    CtaGroupRow: {
        extraSmall: Spacing["spacing-4"],
    },
    CtaGroupColumn: {
        extraSmall: Spacing["spacing-4"],
    },

    // Blog
    FeaturedArticleHeroMargin: {
        extraSmall: Spacing["spacing-8"],
        medium: Spacing["spacing-18"],
    },
    FeaturedArticleHeroHeadlineMargin: {
        extraSmall: Spacing["spacing-2"],
        medium: Spacing["spacing-3"],
        large: Spacing["spacing-4"],
    },
    FeaturedArticleAuthorMargin: {
        extraSmall: Spacing["spacing-8"],
    },
    TopicChipListMargin: {
        extraSmall: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    BlogArticleCardMargin: {
        extraSmall: Spacing["spacing-3"],
        medium: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    BlogArticleTitleMargin: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-18"],
    },
    BlogArticleHeroImageMargin: {
        extraSmall: Spacing["spacing-6"],
        medium: Spacing["spacing-8"],
    },
    BlogArticleBodyMargin: {
        extraSmall: Spacing["spacing-8"],
        medium: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    BlogArticleHeadingMargin: {
        extraSmall: Spacing["spacing-6"],
        large: Spacing["spacing-8"],
    },
    BlogArchiveArticleListMargin: {
        extraSmall: Spacing["spacing-18"],
        large: Spacing["spacing-24"],
    },
    BlogArchiveArticleRowDividerMargin: {
        extraSmall: Spacing["spacing-3"],
    },
    BlogArchiveArticleListHeadlineMargin: {
        extraSmall: Spacing["spacing-4"],
        large: Spacing["spacing-8"],
    },

    // Terminus
    TerminusHomeFeaturedHeadlineMarginBottom: {
        extraSmall: Spacing["spacing-6"],
    },
    TerminusHomeFeaturedTopicMarginBottom: {
        extraSmall: Spacing["spacing-4"],
        large: Spacing["spacing-8"],
    },
    TerminusHomeHeroContentMarginBottom: {
        extraSmall: Spacing["spacing-16"],
    },
    TerminusHomeHeroDescriptionMarginTop: {
        extraSmall: Spacing["spacing-2"],
        large: Spacing["spacing-3"],
    },
    TerminusHomeHeroMarginBottom: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-16"],
    },
    TerminusTopicHeroPretitleMarginBottom: {
        extraSmall: Spacing["spacing-4"],
    },
    TerminusTopicHeroDescriptionMarginTop: {
        extraSmall: Spacing["spacing-3"],
        medium: Spacing["spacing-4"],
    },
    TerminusSidebarTopicsMarginBottom: {
        extraSmall: Spacing["spacing-3"],
    },
    TerminusArticleCardDescriptionMarginTop: {
        extraSmall: Spacing["spacing-3"],
    },
    TerminusArticleCardDescriptionMarginBottom: {
        extraSmall: Spacing["spacing-8"],
    },
    TerminusArticleListMarginBottom: {
        extraSmall: Spacing["spacing-16"],
    },
    TerminusArticleTopDividerMarginTop: {
        extraSmall: Spacing["spacing-6"],
    },
    TerminusArticleTopDividerMarginBottom: {
        extraSmall: Spacing["spacing-8"],
        large: Spacing["spacing-12"],
    },
    TerminusArticleBottomDividerMarginTop: {
        extraSmall: Spacing["spacing-24"],
    },
    TerminusArticleBottomDividerMarginBottom: {
        extraSmall: Spacing["spacing-8"],
    },
    TerminusAuthorCardTopicsMarginBottom: {
        extraSmall: Spacing["spacing-4"],
    },
    TerminusArchiveTitleMarginBottom: {
        extraSmall: Spacing["spacing-6"],
        large: Spacing["spacing-8"],
    },
    TerminusArchiveTitleMarginTop: {
        extraSmall: Spacing["spacing-16"],
        large: Spacing["spacing-24"],
    },
    TerminusArticleParagraphMargin: {
        extraSmall: Spacing["spacing-4"],
    },
    TerminusArticleTitleMargin: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-18"],
    },
    TerminusArticleHeroImageMargin: {
        extraSmall: Spacing["spacing-6"],
        medium: Spacing["spacing-8"],
    },
    TerminusArticleBodyMargin: {
        extraSmall: Spacing["spacing-8"],
        medium: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    TerminusArticleHeadingMargin: {
        extraSmall: Spacing["spacing-6"],
        large: Spacing["spacing-8"],
    },
    TerminusDividerMargin: {
        extraSmall: Spacing["spacing-5"],
    },

    // Input Labels
    InputLabelMargin: {
        extraSmall: Spacing["spacing-3"],
    },

    // Mobile Subnav Link Group
    MobileSubnavLinkGroupTop: {
        extraSmall: Spacing["spacing-4"],
        medium: Spacing["spacing-5"],
    },
    MobileSubnavLinkGroupBottom: {
        extraSmall: Spacing["spacing-8"],
        medium: Spacing["spacing-12"],
    },

    // FAQ Section
    FaqSectionBottomMargin: {
        extraSmall: Spacing["spacing-12"],
    },

    // Agent Mode
    AgentModePageMargin: {
        extraSmall: Spacing["spacing-8"],
        medium: Spacing["spacing-12"],
    },

    AgentModeVideoCaptionMargin: {
        extraSmall: Spacing["spacing-3"],
    },

    AgentModeHeading2MarginTop: {
        extraSmall: Spacing["spacing-18"],
        large: Spacing["spacing-24"],
    },

    AgentModeHeading3MarginTop: {
        extraSmall: Spacing["spacing-8"],
        large: Spacing["spacing-12"],
    },

    // Product Page
    ProductPageParentSlice: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    ProductPageChildSlice: {
        extraSmall: Spacing["spacing-0"],
    },

    // Legacy Benefit Item
    LegacyBenefitItemIcon: {
        extraSmall: Spacing["spacing-3"],
        large: Spacing["spacing-4"],
    },
    LegacyBenefitItemText: {
        extraSmall: Spacing["spacing-1"],
    },
    LegacyBenefitItem: {
        extraSmall: Spacing["spacing-12"],
        small: ColumnGaps.small,
        medium: ColumnGaps.medium,
        large: ColumnGaps.large,
    },

    // Legacy Features Section
    LegacyFeaturesHeroText: {
        extraSmall: Spacing["spacing-5"],
    },

    // Legacy Feature Item (Horizontal)
    LegacyFeatureHorizontalItem: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-0"],
    },
    LegacyFeatureHorizontalItemEyebrow: {
        extraSmall: Spacing["spacing-5"],
    },
    LegacyFeatureHorizontalItemHeadline: {
        extraSmall: Spacing["spacing-2"],
        medium: Spacing["spacing-4"],
    },
    LegacyFeatureHorizontalItemLink: {
        extraSmall: Spacing["spacing-12"],
    },

    // Legacy Feature Item (Vertical)
    LegacyFeatureVerticalItemsContainer: {
        extraSmall: Spacing["spacing-12"],
        medium: ColumnGaps.medium,
        large: ColumnGaps.large,
    },
    LegacyFeatureVerticalItem: {
        extraSmall: Spacing["spacing-4"],
    },
    LegacyFeatureVerticalItemText: {
        extraSmall: Spacing["spacing-1"],
    },
    LegacyFeatureVerticalItemTextContainer: {
        extraSmall: Spacing["spacing-4"],
    },

    // Legacy Testimonial
    LegacyTestimonialHeadshot: {
        extraSmall: Spacing["spacing-6"],
        medium: Spacing["spacing-0"],
    },
    LegacyTestimonialItems: {
        extraSmall: Spacing["spacing-6"],
        medium: Spacing["spacing-8"],
    },
    LegacyTestimonialAttribution: {
        extraSmall: Spacing["spacing-half"],
    },

    // Legacy Tweet Grid
    LegacyTweetGridContainer: {
        extraSmall: Spacing["spacing-6"],
        medium: Spacing["spacing-8"],
    },

    // About Page
    AboutEthosHeadlineMarginBottom: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    AboutHeadlineMarginBottom: {
        extraSmall: Spacing["spacing-18"],
        large: Spacing["spacing-40"],
    },
    AboutPageMarginTop: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-18"],
        large: Spacing["spacing-32"],
    },

    // Careers Page
    CareersContainerMargin: {
        extraSmall: Spacing["spacing-12"],
        medium: Spacing["spacing-18"],
        large: Spacing["spacing-24"],
    },
    CareersHero: {
        extraSmall: Spacing["spacing-8"],
        medium: Spacing["spacing-18"],
    },
    CareersHeroSubHeadline: {
        extraSmall: Spacing["spacing-4"],
        medium: Spacing["spacing-0"],
    },
    CareersHeroCTA: {
        extraSmall: Spacing["spacing-8"],
    },
    CareersHeroTeam: {
        extraSmall: Spacing["spacing-12"],
        large: Spacing["spacing-16"],
    },
    CareersHeroTeamCaption: {
        extraSmall: Spacing["spacing-3"],
    },
    CareersOverviewItemsContainer: {
        extraSmall: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    CareersWorkCTA: {
        extraSmall: Spacing["spacing-8"],
    },
    CareersWorkValuesItemsContainer: {
        extraSmall: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    CareersWorkValuesDescription: {
        extraSmall: Spacing["spacing-2"],
        large: Spacing["spacing-3"],
    },
    CareersBenefitsItemsContainer: {
        extrSmall: Spacing["spacing-12"],
    },
    CareersRolesJobOpenings: {
        extraSmall: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },
    CareersRolesJobLocation: {
        extraSmall: Spacing["spacing-1"],
        medium: Spacing["spacing-0"],
    },
    CareersRolesJobDivider: {
        extraSmall: Spacing["spacing-1"],
    },

    /**
     * Article
     */

    ArticleParagraphMargin: {
        extraSmall: Spacing["spacing-6"],
    },
    ArticleHeading1: {
        extraSmall: Spacing["spacing-12"],
        large: Spacing["spacing-16"],
    },
    ArticleHeading2: {
        extraSmall: Spacing["spacing-8"],
        large: Spacing["spacing-12"],
    },
    ArticleHeading3: {
        extraSmall: Spacing["spacing-4"],
        large: Spacing["spacing-8"],
    },
    ArticleHeading4: {
        extraSmall: Spacing["spacing-4"],
        large: Spacing["spacing-8"],
    },
    ArticleHeading5: {
        extraSmall: Spacing["spacing-4"],
        large: Spacing["spacing-8"],
    },
    ArticleHeading6: {
        extraSmall: Spacing["spacing-4"],
        large: Spacing["spacing-8"],
    },
    ArticleList: {
        extraSmall: Spacing["spacing-4"],
    },
    ArticleLargeMargin: {
        extraSmall: Spacing["spacing-12"],
        large: Spacing["spacing-16"],
    },
    ArticleQuote: {
        extraSmall: Spacing["spacing-8"],
        large: Spacing["spacing-12"],
    },
    ArticleCaption: {
        extraSmall: Spacing["spacing-3"],
    },
    DefaultArticleMargin: {
        extraSmall: Spacing["spacing-12"],
        large: Spacing["spacing-18"],
    },

    // State of CLI
    StateOfCliHeroHeadline: {
        extraSmall: Spacing["spacing-6"],
        large: Spacing["spacing-8"],
    },
    StateOfCliKeyTakeawayTitle: {
        extraSmall: Spacing["spacing-4"],
    },
    StateOfCliKeyTakeawayDescription: {
        extraSmall: Spacing["spacing-2"],
    },

    // Text Header Component
    TextHeaderEyebrow: {
        extraSmall: Spacing["spacing-half"],
    },
    TextHeaderSubheadline: {
        extraSmall: Spacing["spacing-2"],
        medium: Spacing["spacing-4"],
    },
    TextHeaderCta: {
        extraSmall: Spacing["spacing-5"],
        large: Spacing["spacing-6"],
    },

    // Page Hero Components
    DefaultPageHeroTop: {
        extraSmall: Spacing["spacing-8"],
        medium: Spacing["spacing-10"],
        large: Spacing["spacing-14"],
    },
    DefaultPageHeroRowGap: {
        extraSmall: Spacing["spacing-8"],
        small: Spacing["spacing-10"],
        medium: Spacing["spacing-12"],
        extraLarge: Spacing["spacing-14"],
    },

    // 2024 Redesign Page Defaults
    DefaultSectionGap: {
        extraSmall: Spacing["spacing-16"],
        small: Spacing["spacing-18"],
        medium: Spacing["spacing-24"],
        large: Spacing["spacing-32"],
        extraLarge: Spacing["spacing-40"],
    },

    MinimumSectionGap: {
        extraSmall: Spacing["spacing-8"],
        small: Spacing["spacing-10"],
        medium: Spacing["spacing-12"],
        large: Spacing["spacing-16"],
        extraLarge: Spacing["spacing-20"],
    },

    DefaultInterSectionGap: {
        extraSmall: Spacing["spacing-8"],
        small: Spacing["spacing-10"],
        large: Spacing["spacing-16"],
    },

    InterSectionGapSmall: {
        extraSmall: Spacing["spacing-8"],
        large: Spacing["spacing-10"],
    },

    // Spec Sheet
    SpecSheetListSpacing: {
        extraSmall: Spacing["spacing-2"],
    },

    SpecSheetDiveDeeper: {
        extraSmall: Spacing["spacing-1"],
    },

    // Privacy List
    PrivacyListMargin: {
        extraSmall: Spacing["spacing-8"],
        small: Spacing["spacing-10"],
        large: Spacing["spacing-16"],
    },

    PrivacyListSubheadline: {
        extraSmall: Spacing["spacing-4"],
        medium: Spacing["spacing-5"],
    },

    PrivacyListExploreLink: {
        extraSmall: Spacing["spacing-5"],
        extraLarge: Spacing["spacing-6"],
    },

    PrivacyListRowTop: {
        extraSmall: Spacing["spacing-3"],
        large: Spacing["spacing-4"],
    },

    PrivacyListRowBottom: {
        extraSmall: Spacing["spacing-6"],
        small: Spacing["spacing-8"],
        large: Spacing["spacing-10"],
    },

    PrivacyListSubheadlineContainer: {
        extraSmall: Spacing["spacing-1"],
        small: Spacing["spacing-3"],
        large: Spacing["spacing-4"],
    },

    // Billboard Carousel
    BillboardScrollHeadline: {
        extraSmall: Spacing["spacing-5"],
        medium: Spacing["spacing-6"],
        large: Spacing["spacing-8"],
        extraLarge: Spacing["spacing-10"],
    },
    BillboardSubheadline: {
        extraSmall: Spacing["spacing-5"],
        medium: Spacing["spacing-6"],
        large: Spacing["spacing-8"],
    },
};

export type SpacingAll = SpacingStyleKey | SpacingKey;
