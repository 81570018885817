import { css } from "@emotion/react";
import * as _ from "lodash-es";

import { StrapiAspectRatio } from "@/types/strapi";
import {
    ColumnStandardCount,
    GridColumnCount,
    GridColumnSpan,
    GridColumnSpanBase,
    columnCounts,
} from "@/types/tokens/grid";

import {
    Breakpoint,
    BreakpointBases,
    BreakpointNames,
} from "@/tokens/breakpoints";
import { MediaQueries } from "@/tokens/configs/breakpoints_config";
import type { SpacingStyleKey } from "@/tokens/configs/spacing_config";
import { ImageAspectRatioSet, ImageAspectRatioString } from "@/tokens/media";

import { buildStylesByBreakpoint } from "@/util/style_util";

/**
 * Generates the max breakpoint.
 *
 * @param maxBreakpoint
 * @returns
 */
export function getMaxBreakpoint(maxBreakpoint: number) {
    return maxBreakpoint - 0.001;
}

/**
 * Generates the width directive for a media query.
 *
 * @param breakpoint
 * @param maxOrMin
 * @returns
 */
export function getMinOrMaxWidth(
    breakpoint: BreakpointBases,
    maxOrMin: MediaQueries,
) {
    if (maxOrMin === "MIN") {
        return `(min-width: ${breakpoint}rem)`;
    }

    return `(max-width: ${getMaxBreakpoint(breakpoint)}rem)`;
}

/**
 * Generates the media query string.
 *
 * @param breakpoint
 * @param maxOrMin
 * @returns
 */
export function getMediaQuery(
    breakpoint: BreakpointBases,
    maxOrMin: MediaQueries = "MIN",
) {
    return `@media ${getMinOrMaxWidth(breakpoint, maxOrMin)}`;
}

/**
 * Generates the CSS grid column structure depending on columnCount.
 *
 * @param columnCount
 * @returns
 */
export function getTemplateColumns(columnCount: ColumnStandardCount) {
    return `repeat(${columnCount}, minmax(0, 1fr))`;
}

/**
 * Merges the columnStart and columnSpan directives into proper
 * gridColumn CSS syntax
 *
 * @param columnStart
 * @param columnSpan
 * @returns
 */
export function generateGridColumn(
    columnStart: GridColumnCount,
    columnSpan: GridColumnSpan,
) {
    const toGridColumnString = (
        start: ColumnStandardCount,
        span: GridColumnSpanBase,
        size?: Breakpoint,
    ): string => {
        if (span === "full") {
            if (start === 1) {
                return "1 / -1";
            } else {
                const spanValue =
                    size && ["extraSmall", "small"].includes(size)
                        ? columnCounts.small + 1 - start
                        : columnCounts.standard + 1 - start;

                return `${start} / span ${spanValue}`;
            }
        } else {
            return `${start} / span ${span}`;
        }
    };

    // If both arguments are single values, return the grid-column string
    if (
        typeof columnStart === "number" &&
        (typeof columnSpan === "number" || columnSpan === "full")
    ) {
        return toGridColumnString(columnStart, columnSpan);
    }

    // If either argument is an object, return an object with merged values
    const mergedObject: Record<string, string> = {};

    let prevStartValue: ColumnStandardCount | undefined;
    let prevSpanValue: GridColumnSpanBase | undefined;

    BreakpointNames.forEach((size) => {
        const startValue =
            typeof columnStart === "object" ? columnStart[size] : columnStart;

        const spanValue =
            typeof columnSpan === "object" ? columnSpan[size] : columnSpan;

        if (startValue !== undefined || spanValue !== undefined) {
            const finalStartValue =
                startValue !== undefined ? startValue : prevStartValue;

            const finalSpanValue =
                spanValue !== undefined ? spanValue : prevSpanValue;

            if (finalStartValue !== undefined && finalSpanValue !== undefined) {
                mergedObject[size] = toGridColumnString(
                    finalStartValue as ColumnStandardCount,
                    finalSpanValue as GridColumnSpanBase,
                    size,
                );
            }

            // Update previous values for next iteration
            if (startValue !== undefined) {
                prevStartValue = startValue as ColumnStandardCount;
            }
            if (spanValue !== undefined) {
                prevSpanValue = spanValue as GridColumnSpanBase;
            }
        }
    });

    return mergedObject;
}

/**
 * Transforms the aspect ratio type from Strapi
 * and converts it to the proper CSS formatting
 *
 * @param aspectRatio
 */
export function generateAspectRatioStyles(aspectRatio: ImageAspectRatioSet) {
    const formatAspectRatioString = (
        aspectRatioString: ImageAspectRatioString,
    ) => {
        return aspectRatioString.replace("x", " / ");
    };

    if (typeof aspectRatio === "string") {
        return css({ aspectRatio: formatAspectRatioString(aspectRatio) });
    }

    return buildStylesByBreakpoint(
        "aspectRatio",
        _.mapValues(aspectRatio, (_aspectRatio) => {
            if (!_aspectRatio) {
                return;
            }

            return formatAspectRatioString(_aspectRatio);
        }),
    );
}

export function formatAspectRatio(
    aspectRatio?: StrapiAspectRatio,
): ImageAspectRatioString | undefined {
    if (!aspectRatio) {
        return;
    }

    const regex = /(\d+)x(\d+)/;

    const match = aspectRatio.match(regex);

    if (match) {
        return match[0].replace("x", "/") as ImageAspectRatioString;
    }

    return;
}

export function getSectionGap(
    config?: "Default" | "Minimum" | "None",
): SpacingStyleKey {
    switch (config) {
        case "Minimum":
            return "MinimumSectionGap";
        case "None":
            return "None";
        default:
            return "DefaultSectionGap";
    }
}
