// import type { Icon } from "@phosphor-icons/react";
import type { StrapiIcon } from "@/types/strapi";

import { SpacingKey } from "@/tokens/spacing";
import { FontSize } from "@/tokens/typography";

const iconStyleProperties = ["padding", "width"] as const;
export type IconStyleProperty = (typeof iconStyleProperties)[number];

// Button padding
interface IconStyles {
    padding: SpacingKey;
    width: number;
}

/* eslint-disable sort-keys-custom-order/object-keys */
export const iconStyles: {
    [key in FontSize]: IconStyles;
} = {
    TEXT_0625: {
        padding: "spacing-1",
        width: 12,
    },
    TEXT_075: {
        padding: "spacing-1",
        width: 14,
    },
    TEXT_0875: {
        padding: "spacing-1",
        width: 16,
    },
    TEXT_1: {
        padding: "spacing-2",
        width: 18,
    },
    TEXT_1125: {
        padding: "spacing-2",
        width: 20,
    },
    TEXT_125: {
        padding: "spacing-2",
        width: 22,
    },
    TEXT_1375: {
        padding: "spacing-2",
        width: 22,
    },
    TEXT_150: {
        padding: "spacing-2",
        width: 24,
    },
    TEXT_175: {
        padding: "spacing-2",
        width: 28,
    },
    TEXT_2: {
        padding: "spacing-2",
        width: 30,
    },
    // Extending these to avoid type errors
    TEXT_250: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_3: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_350: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_4: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_5: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_6: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_8: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_10: {
        padding: "spacing-2",
        width: 30,
    },
};
/* eslint-enable sort-keys-custom-order/object-keys */

type FunctionalIcon =
    | "Close"
    | "Play"
    | "Plus"
    | "Lightning"
    | "LinkedIn"
    | "Linux"
    | "Mac"
    | "Windows"
    | "X"
    | "Twitter"
    | "YouTube"
    | "TikTok"
    | "Discord"
    | "GitHub"
    | "Bluesky"
    | "RSS"
    | "Arrow Up Right"
    | "Question"
    | "Caret Left"
    | "Caret Right"
    | "RSS";

export type AllIcons = StrapiIcon | FunctionalIcon;
